<template>
  <div class="main">
    <div v-loading="listLoading" class="list">
      <div class="search">
        <div style="display: flex; justify-content: space-between;border: 1px solid #f1f1f1;border-radius: 8px;margin-bottom: 10px">
          <div class="get-info">
            <div>今日待回访数</div>
            <div style="margin-top:10px">{{today}}</div>
          </div>
          <div class="get-info">
            <div>待回访总数</div>
            <div style="margin-top:10px">{{sum}}</div>
          </div>
        </div>
        <div>
        </div>

        <div>
          <div>
            <el-input class="margin-b-sm" style="width: 143px" size="mini" v-model="searchs.name" clearable placeholder="昵称"></el-input>
            <el-input class="margin-b-sm margin-l-sm" style="width: 144px" size="mini" v-model="searchs.wechat" clearable placeholder="微信号"></el-input>
            <el-input class="margin-b-sm " placeholder="归属人微信号" size="mini" v-model="searchs.owner_wechat" clearable></el-input>
            <select-user v-if="$store.state.user.info.role === 1" :role="5" @onSelect="select" :selectUserId="searchs.created_id" class="margin-b-sm"></select-user>
            <el-radio-group v-model="searchs.status" size="mini" class="margin-b-sm">
              <el-radio-button :label="1" >跟进中</el-radio-button>
              <el-radio-button :label="2" >已报名</el-radio-button>
              <el-radio-button :label="3" >无意向</el-radio-button>
            </el-radio-group>
            <el-radio-group v-model="searchs.visit_status" size="mini" class="margin-b-sm">
              <el-radio-button :label="0" >全部</el-radio-button>
              <el-radio-button :label="1" >今日待回访</el-radio-button>
              <el-radio-button :label="2" >所有待回访</el-radio-button>
            </el-radio-group>
          </div>
          <el-button size="mini" type="primary" @click="search">搜索</el-button>

          <el-button size="mini" type="danger"
                     @click="$router.push('/intendedCustomer/add')">新增</el-button>
        </div>
      </div>
      <div v-for="item in list" :key="item.id" :class="item.id === active ? 'active' : ''" class="list-item"
           @click="getDetail(item.id)">
        <div class="top-box">
          <div class="item-info">
            <div class="id_name">
              <div style="display: flex;justify-content: space-between">
                <div style="display: flex;justify-content: start;">
                  <div>{{ item.name }}</div>
                </div>
                <div v-if="item.status===1"><span class="state-up">跟进中</span></div>
                <div v-if="item.status===2" ><span class="state-red">已报名</span></div>
                <div v-if="item.status===3"><span class="state-down">无意向</span></div>
              </div>
              <div>微信号：{{item.wechat}}</div>
              <div style="display: flex;justify-content: start;align-items: center">
                <span>意向度</span>
                <el-rate style="margin-left: 10px"
                    disabled
                    :value="item.star">
                </el-rate>
              </div>
              <div>
                <span class="el-icon-location-information" style="margin-right: 5px"></span>
                <span>{{item.area}}</span>
                <span> / </span>
                <span>{{item.grade}}</span>
              </div>
              <div>
                <span class="el-icon-user-solid" style="margin-right: 5px"></span>
                <span>{{item.owner_name}}</span>
                <span> · </span>
                <span>{{item.owner_wechat}}</span>
              </div>
              <div class="bottom-box"><span class="el-icon-alarm-clock" style="margin-right: 10px"></span>{{ item.next_visit_at }}</div>
            </div>
          </div>
        </div>

      </div>

      <div class="block">
        <el-pagination small :current-page="page" :page-size="pageSize" :total="total" background layout="prev, pager, next,total"
                       @current-change="setPage">
        </el-pagination>
      </div>
    </div>
    <div v-loading="infoLoading || listLoading" class="info-information">
      <el-descriptions border :column="2" size="mini">
        <template slot="extra">
          <el-button type="primary" size="mini" @click="$router.push('/intendedCustomer/set/'+detailId)">编辑</el-button>
        </template>
        <el-descriptions-item label="ID" :span="2">{{detail.id}}</el-descriptions-item>
        <el-descriptions-item label="名称" :span="2">{{detail.name}}</el-descriptions-item>
        <el-descriptions-item label="微信号">
          <span>{{detail.wechat}}</span>
        </el-descriptions-item>
        <el-descriptions-item label="手机号">
          <span>{{detail.phone}}</span>
        </el-descriptions-item>
        <el-descriptions-item label="地区">
          <span>{{detail.area}}</span>
        </el-descriptions-item>
        <el-descriptions-item label="年级">
          <span>{{detail.grade}}</span>
        </el-descriptions-item>
        <el-descriptions-item label="推荐课程">
          <span>{{detail.course}}</span>
        </el-descriptions-item>
        <el-descriptions-item label="报价">
          <span>{{detail.price}}</span>
        </el-descriptions-item>
        <el-descriptions-item label="创建时间">{{
            detail.created_at
          }}</el-descriptions-item>
        <el-descriptions-item label="下次回访时间">{{
            detail.next_visit_at
          }}</el-descriptions-item>
        <el-descriptions-item label="归属人">{{
            detail.owner_name
          }}</el-descriptions-item>
        <el-descriptions-item label="归属微信号">{{
            detail.owner_wechat
          }}</el-descriptions-item>
        <el-descriptions-item label="意向度">
          <div>
            <el-rate v-model="detail.star" disabled text-color="#ff9900"></el-rate>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="状态">
          <el-tag size="mini" v-if="detail.status ===1">跟进中</el-tag>
          <el-tag size="mini" type="danger" v-if="detail.status ===2">已报名</el-tag>
          <el-tag size="mini" type="info" v-if="detail.status ===3">无意向</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="备注" span="2">
         {{ detail.remark }}
        </el-descriptions-item>
        <el-descriptions-item :label="'附件（'+detail.pic.length+'）'" span="2" v-if="detail.pic !== undefined">
          <el-image
              v-if="detail.pic !== undefined && detail.pic.length > 0"
              style="width: 80px; height: 80px"
              :src="detail.pic[0]"
              :preview-src-list="detail.pic">
          </el-image>
        </el-descriptions-item>
      </el-descriptions>

      <div style="margin-top: 10px">
        <div style="display: flex;justify-content: space-between;align-items: center">
          <span>回访记录</span>
          <span><el-button size="mini" type="primary" icon="el-icon-plus" @click="isShowDialog = true">添加记录</el-button></span>
        </div>
      </div>

      <div class="margin-t-sm">
        <el-table
            :header-cell-style="{padding:0}"
            border
            ref="singleTable"
            :data="detail.logs"
            highlight-current-row
            v-loading="loading"
            style="width: 100%;background: #fff;">
          <el-table-column
              prop="id"
              label="ID"
              width="180">
          </el-table-column>
          <el-table-column
              prop="remark"
              label="回访内容">
          </el-table-column>
          <el-table-column
              prop="pic"
              label="附件">
            <template slot-scope="scope">
              <el-image
                  v-if="scope.row.pic !== undefined && scope.row.pic.length > 0"
                  style="width: 80px; height: 80px"
                  :src="imageUrl + scope.row.pic[0]"
                  :preview-src-list="showList(scope.row.pic)">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
              prop="created_at"
              label="创建时间"
              width="180">
          </el-table-column>
          <el-table-column
              label="操作"
              width="145">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="editLog(scope.row)">编辑</el-button>
              <el-button size="mini" type="danger" @click="delLog(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="margin-t-sm" style="display: flex;justify-content: right">
        <el-pagination small background
                       layout="prev, pager, next"
                       :total="detail.total"
                       @current-change="changeDetailPage"
        ></el-pagination>
      </div>

    </div>


    <!-- 添加回访记录 -->

    <el-dialog
        title="添加回访记录"
        :visible="isShowDialog"
        width="600px"
        append-to-body
        >
      <div>
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item label="回访内容" prop="remark" :rules="{ required: true, message: '请输入回访内容', trigger: 'blur' }">
            <el-input v-model="form.remark" type="textarea" rows="7"></el-input>
          </el-form-item>
          <el-form-item
              class="input"
              label="下次回访时间"
              label-position="left"
              prop="next_visit_at"
          >
            <el-date-picker
                size="mini"
                v-model="form.next_visit_at"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="附件图片: " label-position="left">
            <paste-image
                upload-path="sales"
                :showPicArray="form.pic"
                @onDelete="onDelete"
                @onSuccess="onSuccess"
            ></paste-image>
          </el-form-item>
        </el-form>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideDialog" size="mini" >取 消</el-button>
        <el-button type="primary" @click="onSubmit" size="mini" :loading="isLoading">确 定</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { config } from "@/js/config";
import IntendedCustomer from "@/store/modules/intendedCustomer";
import DetailsAdd from "@/views/setting/enterprise/detailsAdd.vue";
import PasteImage from "@/components/pasteImage/index.vue";
import SelectUser from "@/components/selectUser.vue";
export default {
  components: {
    SelectUser,
    PasteImage
  },
  computed: {
    ...mapState({
      role: (state) => state.user.info.role,
      userID: (state) => state.user.info.id,
    }),
  },
  data() {
    return {
      imageUrl:config.imageUrl,
      active: 0,
      searchs:{
        name:'',
        status:1,
        visit_status:0,
        wechat:'',
        created_id:'',
        owner_wechat:'',
      },
      isShowDialog:false,
      isLoading:false,
      searchData: {},
      studentName: "",
      today:0,
      sum:0,
      list: [],
      listLoading: false,
      page: 1,
      users: [],
      pageSize: 5,
      total: 0,
      detail: {},
      infoLoading: false,
      tableLoading: false,
      path: config.imageUrl,
      tableList: [],
      tablePage: 1,
      tablePageSize: 10,
      tableTotal: 0,
      dialogVisible: false,
      form: {
        id:'',
        remark:'',
        next_visit_at:'',
        pic:[]
      },
      searchType: 1,
      selectCurrentUser: {},
      notData: false,
      loading: false,
      modificationDialogVisible: false,
      options: [],
      belonger: {},
      formLoading: false,
      unclaimed:0,
      claimed:0,
      detailId:0,
      detailPage:1,
      detailPageSize:10,
      selectUserId:0
    };
  },
  methods: {
    ...mapActions('intendedCustomer',['getIntendedCustomerList',
      'getIntendedCustomerDetail',
        'setIntendedCustomerVisitLog'
    ]),
    ...mapActions('common',['delete']),
    editInfo() {

    },
    select(e){
      this.searchs.created_id = e
      console.log(e)
    },
    editDetail(){

    },
    showList(imgArray){

      let imgs = []
      imgArray.forEach(item => {
        imgs.push(imageUrl + item)
      })

      return imgs
    },
    async gain() {
      const { res_info } = await this.gainCustomer();
      if (res_info !== "ok") return;
      this.$message.success("获取成功");
      await this.getList();
    },
    onSuccess(pic) {
      this.form.pic = pic;
    },
    changeDetailPage(page){
      this.detailPage = page
      this.getDetail(this.detailId)
    },

    //编辑记录
    editLog(e){
      this.isShowDialog = true
      this.form.remark = e.remark
      this.form.next_visit_at = this.detail.next_visit_at
      this.form.id = e.id
      this.form.intendedCustomerId = this.detailId
      this.form.pic = e.pic
    },
    async delLog(e){
      console.log(e)
      let data = {
        model:'IntendedCustomer\\IntendedCustomerVisitLog',
        id:e.id
      }
      let res = await this.delete(data)
      if(res.ret == 0){
        this.$message.success('删除成功')
        await this.getDetail(this.detailId)
      }
    },
    hideDialog(){
      this.isShowDialog = false
      this.form.remark = ''
      this.form.next_visit_at = ''
      this.form.id = ''
      this.form.intendedCustomerId = ''
      this.form.pic = []
    },
    onDelete(pic) {
      this.form.pic = pic;
    },
    async modification() {
      this.modificationDialogVisible = true;
      this.formLoading = true;
      const { data } = await this.selectCounselor();
      this.options = data;
      this.formLoading = false;
    },


    async onSubmit() {
      this.isLoading = true
      this.form.intendedCustomerId = this.detailId

      this.$refs["form"].validate((valid) => {

      });

      this.setIntendedCustomerVisitLog(this.form).then(async res=> {

        if(res.ret===0){
          this.$message.success('添加成功');
          this.isShowDialog = false;
          this.form.remark = ''
          this.form.next_visit_at = ''
          await this.getDetail(this.detailId);
        }else{
          this.$message.error('添加失败');
        }

      }).finally(()=>{
        this.isLoading = false
      })

    },

    selectUser(id) {
      this.users.forEach((item) => {
        if (id === item.id) {
          this.selectCurrentUser.name = item.name;
          this.selectCurrentUser.id = item.id;
          this.selectCurrentUser.avatar_url = item.avatar_url;
          this.form.userId = item.id;
        }
      });
      this.form.user_id = id;
    },

    clearUser() { },
    searchStudent(v) {
      let searchData = {};
      if (this.searchType === 1) {
        searchData.name = v;
      } else {
        searchData.uid = v;
      }

      this.notData = false;
      this.getUserList(searchData).then((res) => {
        if (res.data.list.length === 0) {
          this.notData = true;
        }
        this.users = res.data.list;
      });
    },
    binding() {
      this.dialogVisible = true;
    },
    async getCustomerSale(customers_id) {
      this.tableLoading = true;
      let form = {
        customers_id,
        page: this.tablePage,
        pageSize: this.tablePageSize,
      };
      const { data } = await this.listCustomerSale(form);

      this.tableList = data.list.map((v) => {
        v.pic = v.pic.map((c) => this.path + c);
        return v;
      });
      this.tableTotal = data.total;
      this.tableLoading = false;
    },
    async getRollowRecord(customers_id) {
      this.tableLoading = true;
      let form = {
        customers_id,
        page: this.tablePage,
        pageSize: this.tablePageSize,
      };
      const { data } = await this.listCustomerFollowRecord(form);
      this.tableList = data.list.map((v) => {
        v.pics = v.pics.map((c) => this.path + c);
        return v;
      });
      this.tableTotal = data.total;
      this.tableLoading = false;
    },
    handleClick(tab, event) {
      localStorage.setItem("customerCard", this.activeName);
    },
    search() {
      this.page = 1;
      this.getList();
    },
    async getDetail(id) {
      this.detailId = id
      this.active = id;
      this.infoLoading = true;
      const { data } = await this.getIntendedCustomerDetail({id:id,page:this.detailPage,pageSize:this.detailPageSize});
      this.detail = data;
      this.infoLoading = false;

      let array = [];
      this.detail.pic.forEach((item)=>{
        array.push(imageUrl+item)
      })

      // this.detail.logs.forEach((item,index) => {
      //   let image = []
      //   item.pic.forEach(img => {
      //     image.push(imageUrl+img)
      //   })
      //   this.detail.logs[index].pic = image
      // })

      this.detail.pic = array

    },

    async getRefundRecord(customers_id) {
      this.tableLoading = true;
      let form = {
        customers_id,
        page: this.tablePage,
        pageSize: this.tablePageSize,
      };
      const { data } = await this.listCustomerRefund(form);

      this.tableList = data.list.map((v) => {
        v.pics = v.pics.map((c) => this.path + c);
        return v;
      });
      this.tableTotal = data.total;
      this.tableLoading = false;
    },
    async getconsultInformation(customers_id) {
      this.tableLoading = true;
      let form = {
        customers_id,
        page: this.tablePage,
        pageSize: this.tablePageSize,
      };
      const { data } = await this.listCustomerConsult(form);

      this.tableList = data.list.map((v) => {
        v.pics = v.pics.map((c) => this.path + c);
        return v;
      });
      this.tableTotal = data.total;
      this.tableLoading = false;
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    async getList() {
      this.listLoading = true;
      let data = {
        ...this.searchs,
        page:this.page,
        pageSize:this.pageSize,
      }

      var res = await this.getIntendedCustomerList(data);

      this.list = res.data.list
      this.total = res.data.total
      this.today = res.data.today
      this.sum = res.data.sum


      this.listLoading = false;
    },
  },
  async mounted() {

    if(this.$route.query.visit){
      this.searchs.visit_status = this.$route.query.visit
    }

    await this.getList();

    if(this.list[0] !== undefined){
      this.detailId = this.list[0].id
      await this.getDetail(this.detailId)
    }

  },
};
</script>

<style scoped>
.main {
  width: 100%;
  height: 100%;
  background: #f4f5f9;
  display: flex;
}

.list {
  background: #fff;
  width: 320px;
  height: 100%;
  overflow: auto;
  margin-right: 10px;
  padding: 10px 10px 40px 10px;
  position: relative;
}

.info-information {
  width: calc(100% - 320px);
  height: 100%;
  overflow: auto;
  background: #fff;
  padding: 20px;
}

.search {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.search-select {
  width: 30%;
  margin-right: 10%;
  margin-bottom: 10px;
}

.search-input {
  width: 60%;
  margin-bottom: 10px;
}

.list-item {
  margin: auto;
  padding: 10px;
  border-radius: 8px;
  background: #fcfcfc;
  margin-bottom: 10px;
  cursor: pointer;
}
.active{
  background-color: #aad4ff;
}
.block {
  width: 100%;
  left: 0;
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
}

.item-info {
  width: 100%;
}

.image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
  background-color: #eee;
}

.id_name {
  line-height: 24px;
}

.top-box {
  width: 100%;
}

.status {
  margin-right: 20px;
}

.bottom-box {

}

.state-red {
  color: red !important;
}


.binding {
  margin: 0 60px;
}

.user-info {
  padding: 10px;
  display: flex;
  align-items: center;
}

.info-top-box {
  display: flex;
  justify-content: space-between;
}

.material {
  padding: 10px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  font-size: 14px;
}

.consult-information {
  margin-top: 10px;
}

.consult-information-title {
  margin: 30px 0;
}

.consult-information-add {
  width: 100%;
  text-align: right;
  /* margin-bottom: 10px; */
}

.consult-information-title span {
  padding: 13px 30px;
  border-radius: 10px 10px 0px 0px;
  background: #e0e0e0;
}
.get-info{
  padding: 10px;
  text-align: center;
  width: 150px;
  border-radius: 8px;
}
</style>
