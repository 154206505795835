<template>
  <div class="padding-sm" v-loading="loading">
    <div style="display: flex;align-items: center">
      <el-radio-group v-model="dateType" size="mini" >
        <el-radio-button label="date">日</el-radio-button>
        <el-radio-button label="week">周</el-radio-button>
        <el-radio-button label="month">月</el-radio-button>
        <el-radio-button label="year">年</el-radio-button>
      </el-radio-group>
      <el-date-picker
          class="margin-l-sm"
          size="mini"
          v-model="date"
          :type="dateType"
          value-format="yyyy-MM-dd"
          :picker-options="{
            firstDayOfWeek:1
          }"
          firstDayOfWeek="1"
          placeholder="选择时间">
      </el-date-picker>
      <el-button class="margin-l-sm " size="mini" type="primary" @click="submit">筛选</el-button>
    </div>

    <div class="list-head" style="margin-top: 10px" v-for="item in list" :key="item.id">
      <el-row :gutter="24">
        <el-col :span="6">
          <div class="text-center text-gray">课程顾问
            <el-tooltip class="" effect="dark" content="课程顾问" placement="top">
              <i class="el-icon-warning-outline" style="cursor:pointer"></i>
            </el-tooltip>
          </div>
          <div class="text-center padding-t ">{{item.name}}</div>
        </el-col>
        <el-col :span="6">
          <div class="text-center text-gray">新增客户
            <el-tooltip class="" effect="dark" content="新增意向客户的数量" placement="top">
              <i class="el-icon-warning-outline" style="cursor:pointer"></i>
            </el-tooltip>
          </div>
          <div class="text-center padding-t ">{{item.new}}</div>
        </el-col>
        <el-col :span="6">
          <div class="text-center text-gray">报名客户
            <el-tooltip class="" effect="dark" content="报名的客户数量" placement="top">
              <i class="el-icon-warning-outline" style="cursor:pointer"></i>
            </el-tooltip>
          </div>
          <div class="text-center padding-t ">{{item.apply}}</div>
        </el-col>
        <el-col :span="6">
          <div class="text-center text-gray">无意向客户
            <el-tooltip class="" effect="dark" content="无意向的客户数量" placement="top">
              <i class="el-icon-warning-outline" style="cursor:pointer"></i>
            </el-tooltip>
          </div>
          <div class="text-center padding-t ">{{item.cancel}}</div>
        </el-col>
      </el-row>
      <el-row :gutter="24" style="margin-top: 25px">
        <el-col :span="6">
          <div class="text-center text-gray">跟进客户数量
            <el-tooltip class="" effect="dark" content="课程顾问" placement="top">
              <i class="el-icon-warning-outline" style="cursor:pointer"></i>
            </el-tooltip>
          </div>
          <div class="text-center padding-t ">{{item.follow}}</div>
        </el-col>
        <el-col :span="6">
          <div class="text-center text-gray">已回访客户
            <el-tooltip class="" effect="dark" content="新增意向客户的数量" placement="top">
              <i class="el-icon-warning-outline" style="cursor:pointer"></i>
            </el-tooltip>
          </div>
          <div class="text-center padding-t ">{{item.visit}}</div>
        </el-col>
        <el-col :span="6">
          <div class="text-center text-gray">待回访客户
            <el-tooltip class="" effect="dark" content="报名的客户数量" placement="top">
              <i class="el-icon-warning-outline" style="cursor:pointer"></i>
            </el-tooltip>
          </div>
          <div class="text-center padding-t ">{{item.unVisit}}</div>
        </el-col>
        <el-col :span="6">
          <div class="text-center text-gray">客户总数
            <el-tooltip class="" effect="dark" content="客户总数" placement="top">
              <i class="el-icon-warning-outline" style="cursor:pointer"></i>
            </el-tooltip>
          </div>
          <div class="text-center padding-t ">{{item.count}}</div>
        </el-col>
      </el-row>
    </div >

  </div>
</template>

<script>

import moment from "moment";
import {mapActions} from "vuex";

export default {
  data(){
    return {
      loading:false,
      list:[],
      type:1,
      dateType:'date',
      dateFormat:'',
      date:moment().format('YYYY-MM-DD')
    }
  },
  watch:{
  },
  methods:{
    ...mapActions('intendedCustomer',['getIntendedCustomerStatistics']),
    submit(){
      this.getStatistics()
    },
    async getStatistics(){
      this.loading = true
      let data = {
        type:this.dateType,
        date:this.date
      }
      let res = await this.getIntendedCustomerStatistics(data)
      this.list = res.data
      console.log(res)
      this.loading = false
    }
  },
  mounted() {
    this.getStatistics()
    console.log(111)
  }
}

</script>

<style scoped>

</style>