import { Api } from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {

    getIntendedCustomerList({commit},data){
        return new Api().getList(config.getIntendedCustomerList, data);
    },
    getIntendedCustomerDetail({commit},data){
        return new Api().getList(config.getIntendedCustomerDetail, data);
    },
    setIntendedCustomer({commit},data){
        return new Api().getList(config.setIntendedCustomer, data);
    },
    setIntendedCustomerVisitLog({commit},data){
        return new Api().getList(config.setIntendedCustomerVisitLog, data);
    },
    getIntendedCustomerVisitCount({commit},data){
        return new Api().getList(config.getIntendedCustomerVisitCount, data);
    },
    getIntendedCustomerStatistics({commit},data){
        return new Api().getList(config.getIntendedCustomerStatistics, data);
    },


}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
