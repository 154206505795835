<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>意向客户</el-breadcrumb-item>
        <el-breadcrumb-item>新增</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="detail-info">
      <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="140px"
          size="mini"
      >
        <el-form-item
            class="input"
            label="昵称: "
            label-position="left"
            prop="name"
        >
          <el-input v-model="form.name"
                    placeholder="输入用户昵称"
                    style="width: 200px"></el-input>
        </el-form-item>

        <el-form-item
            class="input"
            label="微信号"
            label-position="left"
            prop="wechat"
        >
          <el-input
              v-model="form.wechat"
              placeholder="输入意向客户微信号"
              style="width: 200px"
          ></el-input>
        </el-form-item>

        <el-form-item
            class="input"
            label="归属微信号"
            label-position="left"
            prop="owner_wechat"
        >
          <el-input
              v-model="form.owner_wechat"
              placeholder="输入归属微信号 例:xxyw3288"
              style="width: 200px"
          ></el-input>
        </el-form-item>

        <el-form-item
            class="input"
            label="手机号"
            label-position="left"
        >
          <el-input
              v-model="form.phone"
              placeholder="输入用户手机号"
              style="width: 200px"
          ></el-input>
        </el-form-item>

        <el-form-item
            class="input"
            label="地区"
            label-position="left"
            prop="area"
        >
          <el-input
              v-model="form.area"
              placeholder="输入地区"
              style="width: 200px"
          ></el-input>
        </el-form-item>

        <el-form-item class="input" label="年级: " label-position="left" prop="grade">
          <el-select
              v-model="form.grade"
              class="search-select"
              clearable
              placeholder="请选择年级"
              size="mini"
              style="width: 200px"
          >
            <el-option label="一年级" value="一年级"> </el-option>
            <el-option label="二年级" value="二年级"> </el-option>
            <el-option label="三年级" value="三年级"> </el-option>
            <el-option label="四年级" value="四年级"> </el-option>
            <el-option label="五年级" value="五年级"> </el-option>
            <el-option label="六年级" value="六年级"> </el-option>
            <el-option label="七年级" value="七年级"> </el-option>
            <el-option label="八年级" value="八年级"> </el-option>
            <el-option label="九年级" value="九年级"> </el-option>
            <el-option label="其他" value="其他"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
            class="input"
            label="推荐课程"
            label-position="left"
            prop="course"
        >
          <el-input
              v-model="form.course"
              placeholder="输入推荐课程名"
              style="width: 200px"
          ></el-input>
        </el-form-item>

        <el-form-item
            class="input"
            label="报价"
            label-position="left"
            prop="price"
        >
          <el-input
              v-model="form.price"
              placeholder="输入报价"
              style="width: 200px"
          ></el-input>
        </el-form-item>

        <el-form-item
            class="input"
            label="回访时间"
            label-position="left"
            prop="next_visit_at"
        >
          <el-date-picker
              size="mini"
              v-model="form.next_visit_at"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>

        <el-form-item class="input" label="意向度" label-position="left" style="">

          <el-rate style="margin-top: 5px" v-model="form.star"></el-rate>
        </el-form-item>

        <el-form-item label="备注: " label-position="left">
          <el-input
              v-model="form.remark"
              style="width: 500px"
              rows="4"
              type="textarea"
          ></el-input>
        </el-form-item>

        <el-form-item label="附件图片: " label-position="left" prop="pic">
          <paste-image
              upload-path="sales"
              @onDelete="onDelete"
              @onSuccess="onSuccess"
          ></paste-image>
        </el-form-item>

        <el-form-item>
          <el-button
              :loading="loading"
              size="small"
              type="primary"
              @click="onSubmit"
          >
            保存
          </el-button>
          <el-button
              size="small"
              style="margin-left: 10px"
              @click="$router.back()"
          >
            取消
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import PasteImage from "@/components/pasteImage/index";
export default {
  name: "Add",
  components: {
    PasteImage,
  },
  data() {
    return {
      loading: false,
      form: {
        pic: [],
      },
      rules: {
        name: [{ required: true, trigger: "blur", message: "请输入名称" }],
        owner_wechat: [
          { required: true, trigger: "blur", message: "请输入归属微信号" },
        ],
        wechat: [
          { required: true, trigger: "blur", message: "请输入微信号" },
        ],
        area: [
          { required: true, trigger: "blur", message: "请输入地区" },
        ],
        grade: [
          { required: true, trigger: "change", message: "请选择年级" },
        ],
        course: [
          { required: true, trigger: "change", message: "请输入课程名称" },
        ],
        price: [
          { required: true, trigger: "change", message: "请输入报价" },
        ],
        next_visit_at:[
          { required: true, trigger: "change", message: "请输选下次回访时间" },
        ],
        source: [{ required: true, trigger: "change", message: "请选择来源" }],
        consult_type: [
          { required: true, trigger: "change", message: "请选择咨询类型" },
        ],
        remark: [{ required: true, trigger: "blur", message: "请输入备注" }],
        pic: [{ required: true, trigger: "change", message: "请选择图片" }],
      },
    };
  },
  methods: {
    ...mapActions('intendedCustomer',['setIntendedCustomer']),
    onSuccess(pic) {
      this.form.pic = pic;
    },
    onDelete(pic) {
      this.form.pic = pic;
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.addClass();
        }
      });
    },
    async addClass() {
      this.loading = true;
      this.setIntendedCustomer(this.form).then(res => {
        if (res.ret != 0){
          this.$message.error("创建失败");
          return;
        }
        this.$message.success("创建成功");
        this.$router.back();
      }).finally(()=>{
        this.loading = false;
      })



    },
  },
};
</script>
