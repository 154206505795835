<template>
    <div v-loading="loading">
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>课堂</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/liveTrial' }"
                >直播体验课</el-breadcrumb-item
                >
                <el-breadcrumb-item>添加直播体验课</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-form
                :rules="rules"
                ref="form"
                :model="form"
                style="padding: 48px 72px"
                label-position="right"
                label-width="auto"
        >
            <el-form-item label="课程名称: " prop="name">
                <el-input
                        v-model="form.name"
                        style="width: 240px"
                        autofocus
                        clearable
                />
            </el-form-item>

            <el-form-item label="是否上架: " prop="status">
                <el-switch v-model="form.status" :active-value="1" :inactive-value="0" />
            </el-form-item>

            <el-form-item label="封面: " prop="pic">
                <select-images
                        @onSelect="onSelect"
                        @onDelete="onDelete"
                        :image="image"
                ></select-images>
            </el-form-item>

          <el-form-item label="是否免费: " prop="status">
            <el-switch v-model="form.free" :active-value="1" :inactive-value="0" />
          </el-form-item>

            <el-form-item label="金额: " prop="price" v-if="!form.free">
                <el-input v-model="form.price" style="width: 240px" clearable />
            </el-form-item>

          <el-form-item label="留资后可报名: "  >
            <el-switch v-model="form.open_discount" :active-value="1" :inactive-value="0" />
          </el-form-item>

          <el-form-item label="留资后优惠金额: "  >
            <el-input v-model.number="form.discount_amount" style="width: 240px" placeholder="优惠金额"></el-input>
          </el-form-item>

          <el-form-item label="报名后添加老师微信: " >
            <div v-if="this.form.teacher_qr_code!=''">
              <el-image :src="this.imageUrl + this.form.teacher_qr_code"></el-image>
              <br />
              <el-button size="mini" @click="resetImage">重选</el-button>
            </div>
            <div v-else>
              <cover text="二维码图片尺寸300*300像素" @success="onSuccess"></cover>
            </div>

          </el-form-item>

            <el-form-item label="报名时间: " prop="date">
                <el-date-picker
                        size="mini"
                        v-model ="form.date"
                        type="datetimerange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="内容：" prop="content">
                <editor @onChange="onChange" :content="form.content" />
            </el-form-item>

            <el-form-item>
                <el-button @click="$router.back()" size="mini">取消</el-button>
                <el-button type="primary" size="mini" @click="submit">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import { mapActions } from "vuex";
    import editor from "@/components/editor";
    import { config } from "@/js/config";
    import SelectImages from "../../../components/selectImages/index";
    import Cover from "@/components/cover.vue";

    export default {
        components: {Cover,  SelectImages, editor },

        data: () => ({
            imageUrl:config.imageUrl,
            config,
            loading: false,
            image: "",
            poster_templates_plan_pic: "",
            poster_templates_course_pic: "",
            rules: {
                name: [{ required: true,message:"请输入名称" }],
                status: [{ required: true }],
                pic: [{ required: true ,message:"请上传封面"}],
                content: [{ required: true ,message:"请输入内容"}],
                date: [{ required: true ,message:"请选择时间"}],

            },
            value2:[],
            options: [],
            form: {
                id: "",
                name: "",
                date:[],
                pic: "",
                status: true,
                free: 1,
                open_discount: 0,
                discount_amount: 0,
                price: 0,
                notification: true,
                content: "",
                teacher_qr_code: "",
            },
        }),

        methods: {
            ...mapActions("live", ["setLiveTrial","getLiveTrialList"]),

            resetImage(){
              this.form.teacher_qr_code = ''
            },
            onSuccess(image){
              console.log('上传成功=======')
              console.log(image)
              this.form.teacher_qr_code = image
            },
            onChange(content) {
                this.form.content = content;
            },
            onSelect(image) {
                this.image = imageUrl + image.path;
                this.form.pic = image.path;
            },
            onDelete() {
                this.image = "";
            },
            submit() {
              console.log(this.form)
                this.$refs.form.validate(async (valid) => {
                    if(valid){
                        this.loading = true
                        this.setLiveTrial(this.form).then((res) =>{
                            if(res.ret == 0){
                                this.$message.success('操作成功')
                                this.$router.push('/liveTrial')
                            }else{
                                this.$message.error('操作失败')
                            }
                        }).finally(() => {
                            this.loading = false
                        })
                    }
                })
            },
        },

        async created() {
            if(this.$route.query.id !== undefined){
                this.loading = true
                let res = await this.getLiveTrialList({getOne:true,id:this.$route.query.id})
                this.form.id = this.$route.query.id
                this.form.name = res.data.name
                this.form.date = [res.data.start_at,res.data.end_at]
                this.form.pic = res.data.pic
                this.image = config.imageUrl + res.data.pic
                this.form.status = res.data.status
                this.form.price = res.data.price
                this.form.content = res.data.content
                this.form.open_discount = res.data.open_discount
                this.form.discount_amount = res.data.discount_amount
                this.form.teacher_qr_code = res.data.teacher_qr_code
                console.log(this.image)
                this.loading = false
            }
        },
    };
</script>
