import Vue from "vue";
import VueRouter from "vue-router";
import VueCookies from "vue-cookies";

import {Message} from "element-ui";

// 要告诉 vue 使用 vueRouter
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../Index"),
    children: [
      {
        path: "/",
        component: () => import("../views/other/work/list"),
      },
      {
        path: "/slideShow",
        component: () => import("../views/setting/slideShow/list"),
      },
      {
        path: "/poster",
        component: () => import("../views/setting/poster/list"),
      },
      {
        path: "/poster/add",
        component: () => import("../views/setting/poster/add"),
      },
      {
        path: "/poster/edit/:id",
        component: () => import("../views/setting/poster/edit"),
      },
      {
        path: "/startDiagram",
        component: () => import("../views/setting/startDiagram/list"),
      },

      {
        path: "/startDiagram/add",
        component: () => import("../views/setting/startDiagram/add"),
      },
      {
        path: "/startDiagram/edit/:id",
        component: () => import("../views/setting/startDiagram/edit"),
      },
      {
        path: "/ruleDistribution",
        component: () => import("../views/setting/ruleDistribution/list"),
      },
      {
        path: "/ruleDistribution/add",
        component: () => import("../views/setting/ruleDistribution/add"),
      },
      {
        path: "/ruleDistribution/edit/:id",
        component: () => import("../views/setting/ruleDistribution/edit"),
      },

      {
        path: "/vipSet",
        component: () => import("../views/setting/vipSet/list"),
      },
      {
        path: "/vipSet/add",
        component: () => import("../views/setting/vipSet/add"),
      },
      {
        path: "/vipSet/details/:id",
        component: () => import("../views/setting/vipSet/details"),
      },
      {
        path: "/vipSet/edit/:id",
        component: () => import("../views/setting/vipSet/edit"),
      },
      {
        path: "/slideShow/add",
        component: () => import("../views/setting/slideShow/add"),
      },
      {
        path: "/slideShow/edit/:id",
        component: () => import("../views/setting/slideShow/edit"),
      },
      {
        path: "/excerpt",
        component: () => import("../views/content/excerpt/list"),
      },
      {
        path: "/excerpt/add",
        component: () => import("../views/content/excerpt/add"),
      },
      {
        path: "/excerpt/edit/:id",
        component: () => import("../views/content/excerpt/edit"),
      },
      {
        path: "/fileDownload",
        component: () => import("../views/content/fileDownload/list"),
      },
      {
        path: "/fileDownload/add",
        component: () => import("../views/content/fileDownload/add"),
      },
      {
        path: "/fileDownload/edit/:id",
        component: () => import("../views/content/fileDownload/edit"),
      },
      {
        path: "/file",
        component: () => import("../views/resource/file/list"),
      },
      {
        path: "/file/add",
        component: () => import("../views/resource/file/add"),
      },
      {
        path: "/video",
        component: () => import("../views/resource/video/list"),
      },
      {
        path: "/video/add",
        component: () => import("../views/resource/video/add"),
      },
      {
        path: "/audio",
        component: () => import("../views/resource/audio/list"),
      },
      {
        path: "/audio/add",
        component: () => import("../views/resource/audio/add"),
      },
      {
        path: "/images",
        component: () => import("../views/resource/images/list"),
      },
      {
        path: "/images/add",
        component: () => import("../views/resource/images/add"),
      },
      {
        path: "/work",
        component: () => import("../views/other/work/list"),
      },
      {
        path: "/quiz",
        component: () => import("../views/other/quiz/list"),
      },
      {
        path: "/userList",
        component: () => import("../views/user/userList/list"),
      },
      {
        path: "/transfer",
        component: () => import("../views/user/transfer/list"),
      },
      {
        path: "/vipList",
        component: () => import("../views/user/vipList/list"),
      },

      {
        path: "/userSocial",
        component: () => import("../views/user/userSocial/list"),
      },
      {
        path: "/userSocial/detail/:id",
        component: () => import("../views/user/userSocial/detail"),
      },
      {
        path: "/userMessage",
        component: () => import("../views/user/userMessage/list"),
      },
      {
        path: "/userMessage/add",
        component: () => import("../views/user/userMessage/add"),
      },
      {
        path: "/userMessage/detail/:id",
        component: () => import("../views/user/userMessage/edit"),
      },
      {
        path: "/question",
        component: () => import("../views/resource/question/list"),
      },
      {
        path: "/question/detail",
        component: () => import("../views/resource/question/detail"),
      },
      {
        path: "/question/add",
        component: () => import("../views/resource/question/add"),
      },
      {
        path: "/question/edit",
        component: () => import("../views/resource/question/edit"),
      },
      {
        path: "/annotation",
        component: () => import("../views/resource/annotation/list"),
      },
      {
        path: "/annotation/detail/:id",
        component: () => import("../views/resource/annotation/detail"),
      },
      {
        path: "/annotation/add",
        component: () => import("../views/resource/annotation/add"),
      },
      {
        path: "/annotation/edit/:id",
        component: () => import("../views/resource/annotation/edit"),
      },
      {
        path: "/knowledge_1",
        component: () => import("../views/resource/knowledge_1/list"),
      },
      {
        path: "/knowledge_1/detail/:id",
        component: () => import("../views/resource/knowledge_1/detail"),
      },
      {
        path: "/knowledge_1/add",
        component: () => import("../views/resource/knowledge_1/add"),
      },
      {
        path: "/knowledge_1/edit/:id",
        component: () => import("../views/resource/knowledge_1/edit"),
      },
      {
        path: "/knowledge_2",
        component: () => import("../views/resource/knowledge_2/list"),
      },
      {
        path: "/knowledge_2/detail/:id",
        component: () => import("../views/resource/knowledge_2/detail"),
      },
      {
        path: "/knowledge_2/add",
        component: () => import("../views/resource/knowledge_2/add"),
      },
      {
        path: "/knowledge_2/edit/:id",
        component: () => import("../views/resource/knowledge_2/edit"),
      },
      {
        path: "/course",
        component: () => import("../views/classroom/course/list"),
      },
      {
        path: "/course/add",
        component: () => import("../views/classroom/course/add"),
      },
      {
        path: "/course/edit/:id",
        component: () => import("../views/classroom/course/edit"),
      },
      {
        path: "/course/editClass/courseId/:courseId/classId/:classId",
        component: () => import("../views/classroom/course/editClass"),
      },
      {
        path: "/course/detail/:id",
        component: () => import("../views/classroom/course/detail"),
        children: [
          {
            path: "manage",
            component: () => import("../views/classroom/course/manage"),
          },
          {
            path: "student",
            component: () => import("../views/classroom/course/student"),
          },
          {
            path: "file",
            component: () => import("../views/classroom/course/file"),
          },
          {
            path: "notification",
            component: () => import("../views/classroom/course/notification"),
          },
        ],
      },
      {
        path: "/studyProgress/:courseId/userId/:userId",
        component: () => import("../views/classroom/course/studyProgress"),
      },
      {
        path: "/course/addClass/:id",
        component: () => import("../views/classroom/course/addClass"),
      },
      {
        path: "/discount",
        component: () => import("../views/marketing/discount/list"),
      },
      {
        path: "/discount/add",
        component: () => import("../views/marketing/discount/add"),
      },
      {
        path: "/vip",
        component: () => import("../views/marketing/vip/list"),
      },
      {
        path: "/vip/add",
        component: () => import("../views/marketing/vip/add"),
      },
      {
        path: "/vip/detail/:id",
        component: () => import("../views/marketing/vip/detail"),
      },
      {
        path: "/promotion",
        component: () => import("../views/marketing/promotion/list"),
      },
      {
        path: "/promotion/add",
        component: () => import("../views/marketing/promotion/add"),
      },
      {
        path: "/promotion/edit/:id",
        component: () => import("../views/marketing/promotion/edit"),
      },
      {
        path: "/promotion/detail/:id",
        component: () => import("../views/marketing/promotion/detail"),
        children: [
          {
            path: "promotionUsers",
            component: () =>
              import("../views/marketing/promotion/promotionUsers"),
          },
        ],
      },
      {
        path: "/goods",
        component: () => import("../views/store/goods/list"),
      },
      {
        path: "/goods/add",
        component: () => import("../views/store/goods/add"),
      },
      {
        path: "/goods/edit/:id",
        component: () => import("../views/store/goods/edit"),
      },
      {
        path: "/order",
        component: () => import("../views/store/order/list"),
      },
      {
        path: "/order/detail/:id",
        component: () => import("../views/store/order/detail"),
      },
      {
        path: "/stock",
        component: () => import("../views/store/stock/list"),
      },
      {
        path: "/stock/add",
        component: () => import("../views/store/stock/add"),
      },
      {
        path: "/stock/details/:id",
        component: () => import("../views/store/stock/details"),
      },
      {
        path: "/stock/edit/:id",
        component: () => import("../views/store/stock/edit"),
      },
      {
        path: "/expressage",
        component: () => import("../views/salesManage/expressage/list"),
      },
      {
        path: "/salesUserList",
        component: () => import("../views/salesManage/salesUserList"),
      },
      {
        path: "/sales",
        component: () => import("../views/salesManage/sales/list"),
      },
      {
        path: "/salesCourseScheduling",
        component: () => import("../views/salesManage/salesCourseScheduling/index"),
      },
      {
        path: "/salesCourseSchedulingDetail/:id",
        component: () => import("../views/salesManage/salesCourseScheduling/courseSchedulingDetail"),
      },
      {
        path: "/sales/add",
        component: () => import("../views/salesManage/sales/add"),
      },
      {
        path: "/sales/edit/:id",
        component: () => import("../views/salesManage/sales/edit"),
      },
      {
        path: "/salesStatistics",
        component: () => import("../views/salesManage/salesStatistics/list"),
      },
      {
        path: "/salesGroupStatistics",
        component: () => import("../views/salesManage/salesGroup/statistics"),
      },
      {
        path: "/salesStatisticsChart",
        component: () => import("../views/salesManage/salesStatisticsChart"),
      },
      {
        path: "/salesGroup",
        component: () => import("../views/salesManage/salesGroup/list"),
      },
      {
        path: "/power",
        component: () => import("../views/salesManage/power/list"),
      },
      {
        path: "/power/add",
        component: () => import("../views/salesManage/power/add"),
      },
      {
        path: "/power/edit/:id",
        component: () => import("../views/salesManage/power/edit"),
      },
      {
        path: "/groupBuying",
        component: () => import("../views/marketing/groupBuying/list"),
      },
      {
        path: "/groupBuying/add",
        component: () => import("../views/marketing/groupBuying/add"),
      },
      {
        path: "/groupBuying/edit/:id",
        component: () => import("../views/marketing/groupBuying/edit"),
      },

      // 广告
      {
        path: "/adv",
        component: () => import("../views/marketing/adv/list"),
      },
      {
        path: "/adv/add",
        component: () => import("../views/marketing/adv/add"),
      },
      {
        path: "/adv/edit/:id",
        component: () => import("../views/marketing/adv/edit"),
      },
      {
        path: "/adv/detail/:id",
        component: () => import("../views/marketing/adv/detail"),
      },
      // 活动
      {
        path: "/aimActivity",
        component: () => import("../views/marketing/aimActivity/list"),
      },
      {
        path: "/aimActivity/add",
        component: () => import("../views/marketing/aimActivity/add"),
      },
      {
        path: "/aimActivity/detail/:id",
        component: () => import("../views/marketing/aimActivity/detail"),
      },
      //教务
      {
        path: "/teacherCourse",
        component: () => import("../views/teacher/course/list"),
      },
      {
        path: "/teacherCourse/add",
        component: () => import("../views/teacher/course/add"),
      },
      {
        path: "/teacherCourse/edit/:id",
        component: () => import("../views/teacher/course/edit"),
      },
      {
        path: "/teacherCourse/addClass/:id",
        component: () => import("../views/teacher/course/addClass"),
      },
      {
        path: "/teacherCourse/addFile/:id",
        component: () => import("../views/teacher/course/addFile"),
      },
      {
        path: "/teacherCourse/editClass/teacherCourseId/:teacherCourseId/classId/:id",
        component: () => import("../views/teacher/course/editClass"),
      },
      {
        path: "/teacherCourse/detail/:id",
        component: () => import("../views/teacher/course/detail"),
        children: [
          {
            path: "manage",
            component: () => import("../views/teacher/course/manage"),
          },
          {
            path: "file",
            component: () => import("../views/teacher/course/file"),
          },
        ],
      },
      // 教师录单
      {
        path: "/teacherRecord",
        component: () => import("../views/teacher/record/list"),
      },
      {
        path: "/teacherRecord/add",
        component: () => import("../views/teacher/record/add"),
      },
      {
        path: "/teacherRecord/edit/:id",
        component: () => import("../views/teacher/record/edit"),
      },

      {
        path: "/errorList",
        component: () => import("../views/content/errorList/list"),
      },

      {
        path: "/evaluation",
        component: () => import("../views/content/evaluation/list.vue"),
      },
      {
        path: "/evaluation/add",
        component: () => import("../views/content/evaluation/add.vue"),
      },
      {
        path: "/evaluation/edit/:id",
        component: () => import("../views/content/evaluation/edit.vue"),
      },
      {
        path: "/evaluation/details/:id",
        component: () => import("../views/content/evaluation/details.vue"),
      },
      {
        path: "/evaluation/details/:id/addClass",
        component: () => import("../views/content/evaluation/addClass.vue"),
      },
      {
        path: "/evaluation/details/:id/editClass/:classId",
        component: () => import("../views/content/evaluation/editClass.vue"),
      },

      {
        path: "/evaluation/details/:id/optionConfiguration/:classId",
        component: () =>
          import("../views/content/evaluation/optionConfiguration.vue"),
      },

      {
        path: "/evaluation/details/:id/scoreConfiguration/:classId",
        component: () =>
          import("../views/content/evaluation/scoreConfiguration.vue"),
      },
      {
        path: "/evaluation/details/:id/scoreConfiguration/:classId/scoreConfigurationAdd",
        component: () =>
          import("../views/content/evaluation/scoreConfigurationAdd.vue"),
      },
      {
        path: "/evaluation/details/:id/scoreConfiguration/:classId/scoreConfigurationEdit/:scoreConfigurationId",
        component: () =>
          import("../views/content/evaluation/scoreConfigurationEdit.vue"),
      },

      //专栏
      {
        path: "/specialColumn",
        component: () => import("../views/specialColumn/recommend/list"),
      },
      {
        path: "/specialColumn/add",
        component: () => import("../views/specialColumn/recommend/add"),
      },
      {
        path: "/specialColumn/edit/:id",
        component: () => import("../views/specialColumn/recommend/edit"),
      },
      {
        path: "/specialColumn/detail/:id",
        component: () => import("../views/specialColumn/recommend/detail"),
        children: [
          {
            path: "content",
            component: () => import("../views/specialColumn/recommend/content"),
          },
        ],
      },
      {
        path: "/specialColumn/addContent/:id",
        component: () => import("../views/specialColumn/recommend/addContent"),
      },
      {
        path: "/specialColumn/editContent/:id/:specialColumnId",
        component: () => import("../views/specialColumn/recommend/editContent"),
      },

      // 班级管理

      {
        path: "/manage",
        component: () => import("../views/classManage/manage"),
      },
      {
        path: "/classList",
        component: () => import("../views/classManage/classList"),
      },

      {
        path: "/classList/add",
        component: () => import("../views/classManage/classList/add"),
      },
      {
        path: "/classList/edit",
        component: () => import("../views/classManage/classList/edit"),
      },
      {
        path: "/classMessage",
        component: () => import("../views/classManage/message/index"),
      },
      {
        path: "/message",
        component: () => import("../views/message"),
      },

      {
        path: "/checkCourse",
        component: () => import("../views/classroom/checkCourse/list"),
      },
      {
        path: "/checkCourse/add",
        component: () => import("../views/classroom/checkCourse/add"),
      },
      {
        path: "/checkCourse/edit/:id",
        component: () => import("../views/classroom/checkCourse/edit"),
      },
      {
        path: "/checkCourse/addClass/:planId/:courseId",
        component: () => import("../views/classroom/checkCourse/addClass"),
      },
      {
        path: "/checkCourse/editClass/:id/",
        component: () => import("../views/classroom/checkCourse/editClass"),
      },
      {
        path: "/checkCourse/detail/:id",
        component: () => import("../views/classroom/checkCourse/detail"),
        children: [
          {
            path: "manage",
            component: () => import("../views/classroom/checkCourse/manage"),
          },
          {
            path: "student",
            component: () => import("../views/classroom/checkCourse/student"),
          },
        ],
      },
      //图书管理
      {
        path: "/audioBooks",
        component: () => import("../views/hearBook/audioBooks/list"),
      },
      {
        path: "/audioBooks/add",
        component: () => import("../views/hearBook/audioBooks/add"),
      },
      {
        path: "/audioBooks/detail/:id",
        component: () => import("../views/hearBook/audioBooks/detail"),
      },
      {
        path: "/audioBooks/details/:id",
        component: () => import("../views/hearBook/audioBooks/details"),
        children: [
          {
            path: "manage",
            component: () => import("../views/hearBook/audioBooks/manage"),
          },
          {
            path: "student",
            component: () => import("../views/hearBook/audioBooks/student"),
          },
        ],
      },
      {
        path: "/audioBooks/addClass/:planId",
        component: () => import("../views/hearBook/audioBooks/addClass"),
      },
      {
        path: "/audioBooks/detailClass/:planId/:courseId",
        component: () => import("../views/hearBook/audioBooks/detailClass"),
      },
      {
        path: "/cate",
        component: () => import("../views/hearBook/cate/list"),
      },
      {
        path: "/cate/add",
        component: () => import("../views/hearBook/cate/add"),
      },
      {
        path: "/cate/detail/:id",
        component: () => import("../views/hearBook/cate/detail"),
      },
      //会员答题
      {
        path: "/game",
        component: () => import("../views/game"),
      },
      {
        path: "/game/add",
        component: () => import("../views/game/add"),
      },
      {
        path: "/game/edit/:id",
        component: () => import("../views/game/edit"),
      },
      {
        path: "/game/details/:id",
        component: () => import("../views/game/details"),
        children: [
          {
            path: "manage",
            component: () => import("../views/game/manage"),
          },
        ],
      },
      {
        path: "/game/addClass/:id",
        component: () => import("../views/game/classAdd.vue"),
      },
      {
        path: "/game/editClass/:id/:games_id",
        component: () => import("../views/game/classEdit.vue"),
      },

      //推广用户
      {
        path: "/extension",
        component: () => import("../views/marketing/extension/list.vue"),
      },
      {
        path: "/extension/add",
        component: () => import("../views/marketing/extension/add.vue"),
      },
      {
        path: "/extension/edit/:id",
        component: () => import("../views/marketing/extension/edit.vue"),
      },

      {
        path: "/promote",
        component: () => import("../views/marketing/promote/list.vue"),
      },
      {
        path: "/promote/add",
        component: () => import("../views/marketing/promote/add.vue"),
      },
      {
        path: "/withdraw",
        component: () => import("../views/popularize/withdraw/list.vue"),
      },
      {
        path: "/promotionRecord",
        component: () => import("../views/popularize/promotionRecord/list.vue"),
      },

      /**
       * 直播
       */
      {
        path: "/liveTrial",
        component: () =>
          import("../views/classroom/liveCourse/liveTrialList.vue"),
      },
      {
        path: "/addLiveTrial",
        component: () =>
          import("../views/classroom/liveCourse/addLiveTrial.vue"),
      },
      {
        path: "/liveDiscount",
        component: () =>
            import("../views/classroom/liveCourse/liveDiscount.vue"),
      },
      {
        path: "/liveTrialDetail/:id",
        component: () =>
          import("../views/classroom/liveCourse/liveTrialDetail.vue"),
      },
      {
        path: "/liveUser",
        component: () => import("../views/classroom/liveCourse/user.vue"),
      },
      {
        path: "/liveOrder",
        component: () => import("../views/classroom/liveOrder/list.vue"),
      },
      {
        path: "/liveCourse",
        component: () => import("../views/classroom/liveCourse/list.vue"),
      },
      {
        path: "/liveCourseClass/:courseId",
        component: () =>
          import("../views/classroom/liveCourse/courseClass.vue"),
      },

      {
        path: "/myData",
        component: () => import("../views/flow/myData/list.vue"),
      },
      {
        path: "/customerCard",
        component: () => import("../views/flow/customerCard/list.vue"),
      },
      {
        path: "/customerCard/add",
        component: () =>
          import("../views/flow/customerCard/customerCardAdd.vue"),
      },
      {
        path: "/customerCard/edit/:id",
        component: () =>
          import("../views/flow/customerCard/customerCardEdit.vue"),
      },
      {
        path: "/customerCard/customerConsultAdd/:id",
        component: () =>
          import("../views/flow/customerCard/customerConsultAdd.vue"),
      },
      {
        path: "/customerCard/customerConsultEdit/:id/:customerConsultId",
        component: () =>
          import("../views/flow/customerCard/customerConsultEdit.vue"),
      },
      {
        path: "/customerCard/refundRecordAdd/:id",
        component: () =>
          import("../views/flow/customerCard/refundRecordAdd.vue"),
      },
      {
        path: "/customerCard/refundRecordEdit/:id/:refundRecordId",
        component: () =>
          import("../views/flow/customerCard/refundRecordEdit.vue"),
      },
      {
        path: "/customerCard/rollowRecordAdd/:id",
        component: () =>
          import("../views/flow/customerCard/rollowRecordAdd.vue"),
      },
      {
        path: "/customerCard/rollowRecordEdit/:id/:rollowRecordId",
        component: () =>
          import("../views/flow/customerCard/rollowRecordEdit.vue"),
      },

      {
        path: "/flowCrew",
        component: () => import("../views/flow/flowCrew/list.vue"),
      },
      {
        path: "/flowCrew/add",
        component: () => import("../views/flow/flowCrew/add.vue"),
      },
      {
        path: "/flowCrew/edit/:id",
        component: () => import("../views/flow/flowCrew/edit.vue"),
      },
      {
        path: "/flowStatistics",
        component: () => import("../views/flow/flowStatistics/list.vue"),
      },
      {
        path: "/listAuthor",
        component: () => import("../views/poetry/listAuthor/list.vue"),
      },
      {
        path: "/listAuthor/add",
        component: () => import("../views/poetry/listAuthor/add.vue"),
      },
      {
        path: "/listAuthor/edit/:id",
        component: () => import("../views/poetry/listAuthor/edit.vue"),
      },
      {
        path: "/listType",
        component: () => import("../views/poetry/listType/list.vue"),
      },
      {
        path: "/listType/add",
        component: () => import("../views/poetry/listType/add.vue"),
      },
      {
        path: "/listType/edit/:id",
        component: () => import("../views/poetry/listType/edit.vue"),
      },
      {
        path: "/listDynasty",
        component: () => import("../views/poetry/listDynasty/list.vue"),
      },
      {
        path: "/listDynasty/add",
        component: () => import("../views/poetry/listDynasty/add.vue"),
      },
      {
        path: "/listDynasty/edit/:id",
        component: () => import("../views/poetry/listDynasty/edit.vue"),
      },
      {
        path: "/listPoem",
        component: () => import("../views/poetry/listPoem/list.vue"),
      },
      {
        path: "/listPoem/add",
        component: () => import("../views/poetry/listPoem/add.vue"),
      },
      {
        path: "/listPoem/edit/:id",
        component: () => import("../views/poetry/listPoem/edit.vue"),
      },
      {
        path: "/listPoem/details/:id",
        component: () => import("../views/poetry/listPoem/details.vue"),
      },
      {
        path: "/deriveMessage",
        component: () => import("../views/deriveMessage.vue"),
      },
      {
        path: "/studentNameCard",
        component: () => import("../views/record/studentNameCard/list.vue"),
      },

      {
        path: "/studentNameCard/edit/:id",
        component: () => import("../views/record/studentNameCard/edit.vue"),
      },

      {
        path: "/studentNameCard/studentProfileRefundAdd/:id",
        component: () =>
          import(
            "../views/record/studentNameCard/components/studentProfileRefund/add.vue"
          ),
      },
      {
        path: "/studentNameCard/studentProfileRefundEdit/:id/:classId",
        component: () =>
          import(
            "../views/record/studentNameCard/components/studentProfileRefund/edit.vue"
          ),
      },
      {
        path: "/studentNameCard/recently/:id",
        component: () =>
          import("../views/record/studentNameCard/components/recently/add.vue"),
      },
      {
        path: "/studentNameCard/recently/:id/:classId",
        component: () =>
          import(
            "../views/record/studentNameCard/components/recently/edit.vue"
          ),
      },
      {
        path: "/teacherGrouping",
        component: () => import("../views/classManage/teacher/list.vue"),
      },
      {
        path: "/advertising",
        component: () => import("../views/setting/advertising/list.vue"),
      },
      {
        path: "/advertising/edit/:id",
        component: () => import("../views/setting/advertising/edit.vue"),
      },
      {
        path: "/advertising/add",
        component: () => import("../views/setting/advertising/add.vue"),
      },
      {
        path: "/accessory",
        component: () => import("../views/mailbox/accessory/index.vue"),
      },
      {
        path: "/accessory/add",
        component: () => import("../views/mailbox/accessory/add.vue"),
      },
      {
        path: "/accessory/edit/:id",
        component: () => import("../views/mailbox/accessory/edit.vue"),
      },
      {
        path: "/send",
        component: () => import("../views/mailbox/send/index.vue"),
      },
      {
        path: "/sendRecord",
        component: () => import("../views/mailbox/sendRecord/index.vue"),
      },
      {
        path: "/dataExtract",
        component: () => import("../views/marketing/dataExtract/list.vue"),
      },
      {
        path: "/dataExtract/add",
        component: () => import("../views/marketing/dataExtract/add.vue"),
      },
      {
        path: "/dataExtract/edit/:id",
        component: () => import("../views/marketing/dataExtract/edit.vue"),
      },

      {
        path: "/campus",
        component: () => import("../views/collaborate/campus/list.vue"),
      },
      {
        path: "/campus/add",
        component: () => import("../views/collaborate/campus/add.vue"),
      },
      {
        path: "/campus/edit/:id",
        component: () => import("../views/collaborate/campus/edit.vue"),
      },
      {
        path: "/campusRecord",
        component: () => import("../views/collaborate/campusRecord/list.vue"),
      },
      {
        path: "/sendRecord",
        component: () => import("../views/mailbox/sendRecord/index.vue"),
      },
      {
        path: "/gift",
        component: () => import("../views/setting/gift/list.vue"),
      },
      {
        path: "/gift/add",
        component: () => import("../views/setting/gift/add.vue"),
      },
      {
        path: "/gift/edit/:id",
        component: () => import("../views/setting/gift/edit.vue"),
      },
      {
        path: "/giftSet",
        component: () => import("../views/setting/giftSet/list.vue"),
      },
      {
        path: "/giftSet/add",
        component: () => import("../views/setting/giftSet/add.vue"),
      },
      {
        path: "/giftSet/edit/:id",
        component: () => import("../views/setting/giftSet/edit.vue"),
      },
      {
        path: "/avatarFrame",
        component: () => import("../views/setting/avatarFrame/list.vue"),
      },
      {
        path: "/avatarFrame/add",
        component: () => import("../views/setting/avatarFrame/add.vue"),
      },
      {
        path: "/avatarFrame/edit/:id",
        component: () => import("../views/setting/avatarFrame/edit.vue"),
      },
      {
        path: "/enterprise",
        component: () => import("../views/setting/enterprise/list.vue"),
      },
      {
        path: "/enterprise/add",
        component: () => import("../views/setting/enterprise/add.vue"),
      },
      {
        path: "/enterprise/edit/:id",
        component: () => import("../views/setting/enterprise/edit.vue"),
      },
      {
        path: "/enterprise/details/:id",
        component: () => import("../views/setting/enterprise/details"),
      },
      {
        path: "/enterprise/details/:id/detailsAdd",
        component: () => import("../views/setting/enterprise/detailsAdd"),
      },
      {
        path: "/enterprise/details/:id/detailsEdit/:detailsID",
        component: () => import("../views/setting/enterprise/detailsEdit"),
      },
      {
        path: "/socialTheme",
        component: () => import("../views/setting/socialTheme/list"),
      },
      {
        path: "/socialTheme/add",
        component: () => import("../views/setting/socialTheme/add"),
      },
      {
        path: "/socialTheme/edit/:id",
        component: () => import("../views/setting/socialTheme/edit")
      },
      {
        path: "/appUpdate",
        component: () => import("../views/setting/appUpdate/index")
      },
        //排课
      {
        path: "/courseScheduling",
        component: () => import("../views/classManage/courseScheduling/list")
      },
      {
        path: "/schedulingTeacher",
        component: () => import("../views/classManage/courseScheduling/schedulingTeacher")
      },
      {
        path: "/addSchedulingTeacher",
        component: () => import("../views/classManage/courseScheduling/addSchedulingTeacher")
      },
      {
        path: "/editSchedulingTeacher/:id",
        component: () => import("../views/classManage/courseScheduling/editSchedulingTeacher")
      },
      {
        path: "/addCourseScheduling",
        component: () => import("../views/classManage/courseScheduling/addCourseScheduling")
      },
      {
        path: "/batchCourseScheduling",
        component: () => import("../views/classManage/courseScheduling/batchCourseScheduling")
      },
      {
        path: "/editCourseScheduling/:id",
        component: () => import("../views/classManage/courseScheduling/editCourseScheduling")
      },
      {
        path: "/courseSchedulingDetail/:id",
        component: () => import("../views/classManage/courseScheduling/courseSchedulingDetail")
      },
      {
        path: "/courseList",
        component: () => import("../views/classManage/courseScheduling/courseList")
      },
      {
        path: "/intendedCustomer",
        component: () => import("../views/salesManage/intendedCustomer/list")
      },
      {
        path: "/intendedCustomerStatist",
        component: () => import("../views/salesManage/intendedCustomer/statist")
      },
      {
        path: "/intendedCustomer/add",
        component: () => import("../views/salesManage/intendedCustomer/add")
      },
      {
        path: "/intendedCustomer/set/:id",
        component: () => import("../views/salesManage/intendedCustomer/set")
      }
    ],
  },

  {
    path: "/login",
    component: () => import("../Login"),
  },
  {
    path: "/wxLogin",
    component: () => import("../views/wxLogin"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
  if (from.path == "/" && to.path == "/" && to.query.code != undefined)
    next("/wxLogin?code=" + to.query.code);
  else {
    const isLogin = VueCookies.get("isLogin");

    if (to.path == "/login") next();
    else {
      if (isLogin == "true") return next();

      if (to.path == "/wxLogin" && to.query.code != "") return next();

      Message({
        message: "请先登录!",
        type: "error",
      });

      next("/login");
    }
  }
});

export default router;
