<template>
    <div>
        <div class="main-title">课程小节</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head" >
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">创建课程后会自动同步到class in后台 </li>
                        <li data-v-69874ad0="">点击上课会自动打开class in 软件</li>
                        <li data-v-69874ad0="">若未安装class in软件 <a style="color: #f00;margin-left: 5px" data-id="classInDownLoad_win7">请点击此下载</a><br /></li>
                    </ul>
                </div>
            </div>

            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="课时管理" name="class">
                    <class-list ></class-list>
                </el-tab-pane>
                <el-tab-pane label="用户管理" name="user" v-if="role === 1 || role === 9" >
                    <course-user ></course-user>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
    import ClassList from "./classList";
    import CourseUser from "./courseUser";
    import {mapState} from "vuex";

    export default {
        name: "template-list",
        components: {CourseUser, ClassList},
        computed:{
            ...mapState({
                role : state => state.user.info.role,
                userId : state => state.user.info.id,
            })
        },
        data(){
            return {
                activeName:'class',
                imageUrl:config.imageUrl,
            }
        },
        methods:{
            handleClick(e){
                console.log(e.name)
            }
        },
        mounted() {

        }
    }
</script>

<style>

</style>
